///
/// Editorial by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Main */

	#main {
		@include vendor('flex-grow', '1');
		@include vendor('flex-shrink', '1');
		width: 100%;

		> .inner {
			@include padding(0, 6em);
			margin: 0 auto;
			max-width: 110em;

			> section {
				@include padding(6em, 0);
				border-top: solid 2px _palette(border);

				&:first-of-type {
				 	border-top: 0 !important;
				}
			}
		}

		@include breakpoint('<=xlarge') {
			> .inner {
				@include padding(0, 5em);

				> section {
					@include padding(5em, 0);
				}
			}
		}

		@include breakpoint('<=large') {
			> .inner {
				@include padding(0, 4em);

				> section {
					@include padding(4em, 0);
				}
			}
		}

		@include breakpoint('<=small') {
			> .inner {
				@include padding(0, 2em);

				> section {
					@include padding(3em, 0);
				}
			}
		}
	}